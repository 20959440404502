import React from "react";
import Select from "react-select";
import { withTranslation } from "react-i18next";

class SelectDropdownSearch extends React.Component {
  constructor(props) {
    super(props);
  }
  state = {
    selectedOption: null,
  };
  handleChange = (selectedOption) => {
    this.setState({ selectedOption }, () => this.props.updateContactUs("country", this.state.selectedOption));
  };
  render() {
    const { selectedOption } = this.state;

    return (
      <div class="form-group form-group-select">
        <Select
          class="form-select"
          placeholder={this.props.t("fold7.country")}
          value={this.props.state.contactUs != undefined ? this.props.state.contactUs.country : null}
          onChange={this.handleChange}
          options={this.props.state.countryDropDon}
        />
        <span></span>
      </div>
    );
  }
}
export default withTranslation()(SelectDropdownSearch);
